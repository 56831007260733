import {
  Button,
  Checkbox,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  styled,
  tableCellClasses,
} from '@mui/material';
import SkeletonLoader from '../SkeletonLoader';
import { useEffect, useMemo, useRef, useState } from 'react';
import { ApiHttpClient } from '../../interceptor/axiosInterceptor';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useDebouncedSearch from '../../hooks/useDebouncedSearch';
import { StyledTableRow } from '../../styles/tableStyles';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Model from '../model';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { error, success } from '../Toast';
import { paginationRowsPerPage } from '../../const/strings';
import ConfirmationDialog from '../deleteModal';

function GroupsMembers() {
  const state = useSelector((state: any) => state.config);
  const orgId = useMemo(() => state.orgId, [state.orgId]);
  const initialRender = useRef(true);
  const { clientid } = useParams();
  const [loading, setLoading] = useState(true);
  const [rows, setMembers] = useState([]);
  const [presentData, setPresentData] = useState(0);
  const dispatch = useDispatch();
  const [users, SetUsersData] = useState([]);
  const [selected, setSelected] = useState<any[]>([]);
  const [selectedUser, setSelectedUser] = useState<string[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [count, setCount] = useState(10);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [firstValue, setFirstValue] = useState(0);
  const [usersCount, setUsersCount] = useState(10);
  const [usersPage, setUsersPage] = useState(0);
  const [usersRowsPerPage, setUsersRowsPerPage] = useState(10);
  const [usersFirstValue, setUsersFirstValue] = useState(0);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState('');
  const [userChecked, setUserChecked] = useState(false);
  const accessArray = Object.values(state.realmAccess ?? {}).flat();
  const [searchTerm, setSearchTerm] = useDebouncedSearch((debouncedTerm: any) => {
    fetchUsersData(debouncedTerm);
  });
  const [searchAssignedTerm, setSearchAssignedTerm] = useDebouncedSearch((debouncedTerm: any) => {
    fetchData(debouncedTerm);
  });
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    fetchData(searchAssignedTerm);
  }, [orgId, page, rowsPerPage]);

  useEffect(() => {
    fetchUsersData(searchTerm);
  }, [orgId, usersPage, usersRowsPerPage]);
  useEffect(() => {
    disableLeave();
  }, [selectedUser, rows]);

  const fetchData = async (query = '') => {
    try {
      const url = `admin/realms/${orgId}/groups/${clientid}/members?first=${firstValue}&max=${rowsPerPage!}&global=false`;
      const paginationUrl = query
        ? `admin/realms/${orgId}/groups/${clientid}/members?search=${query}`
        : `admin/realms/${orgId}/groups/${clientid}/members`;
      const paginationResponse = await ApiHttpClient.get(paginationUrl);
      const response = await ApiHttpClient.get(url);
      const apiData = response ? response : [];

      if (apiData) {
        if (query) {
          setPage(0);
        }
        const filteredData = query ? paginationResponse.filter((ele: any) => ele.username.includes(query)) : apiData;
        setMembers(filteredData);
        setPresentData(query ? filteredData.length + 1 : paginationResponse.length + 1);
        setCount(query ? filteredData.length : paginationResponse.length);
      } else {
        console.error('API returned undefined data');
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchUsersData = async (query = '') => {
    try {
      const getUrl = query
        ? `admin/realms/${orgId}/users?first=${usersFirstValue}&max=${
            usersRowsPerPage + presentData
          }&briefRepresentation=true&q=&search=${query}`
        : `admin/realms/${orgId}/users?first=${usersFirstValue}&max=${usersRowsPerPage + presentData}`;
      const Usersresponse = await ApiHttpClient.get(getUrl);
      const paginationUrl =
        searchTerm == ''
          ? `admin/realms/${orgId}/users/count?search=${query}`
          : `admin/realms/${orgId}/users/count?q=&search=${query}`;
      const paginationResponse = await ApiHttpClient.get(paginationUrl);
      const membersUrl = `admin/realms/${orgId}/groups/${clientid}/members`;
      const memberResponse: any = await ApiHttpClient.get(membersUrl);
      if (Usersresponse && Usersresponse.length > 0) {
        const filteredUsers = Usersresponse.filter((ele: any) => {
          return !memberResponse.some((item: any) => item.id === ele.id);
        });
        SetUsersData(filteredUsers ? filteredUsers : []);
        setUsersCount(paginationResponse - presentData);
        setLoading(false);
      } else {
        SetUsersData([]);
        setUsersCount(paginationResponse);
        setLoading(false);
      }
    } catch (error) {
      console.log('Error Fetching data:', error);
      setLoading(false);
    }
  };

  const handleSearchInputChange = (e: any) => {
    const value = e.target.value;
    setSearchTerm(value);
    setUsersPage(0);
    setUsersFirstValue(0);
  };

  const handleAssignedSearchInputChange = (e: any) => {
    const value = e.target.value;
    setSearchAssignedTerm(value);
    setPage(0);
    setFirstValue(0);
  };

  const handleClickOpen = async () => {
    const getUrl = searchTerm
      ? `admin/realms/${orgId}/users?first=${usersFirstValue}&max=${
          usersRowsPerPage + presentData
        }&briefRepresentation=true&q=&search=${searchTerm}`
      : `admin/realms/${orgId}/users?first=${usersFirstValue}&max=${
          usersRowsPerPage + presentData
        }&search=${searchTerm}`;
    const membersUrl = `admin/realms/${orgId}/groups/${clientid}/members`;
    let memberResponse = await ApiHttpClient.get(membersUrl);
    const Usersresponse = await ApiHttpClient.get(getUrl);
    const paginationUrl =
      searchTerm == ''
        ? `admin/realms/${orgId}/users/count?search=${searchTerm}`
        : `admin/realms/${orgId}/users/count?q=&search=${searchTerm}`;
    const filteredUsers =
      Usersresponse.length > 0
        ? Usersresponse.filter((ele: any) => {
            return !memberResponse.some((item: any) => item.id === ele.id);
          })
        : [];
    const paginationResponse = await ApiHttpClient.get(paginationUrl);
    // setSelected(memberResponse);
    setUsersCount(paginationResponse - presentData);
    SetUsersData(filteredUsers);
    setOpenModal(true);
  };

  const handleLeave = async (query = '') => {
    try {
      if (query === '') {
        const deletePromises = selectedUser.map((id: string) => {
          const url = `admin/realms/${orgId}/users/${id}/groups/${clientid}`;
          return ApiHttpClient.delete(url, {});
        });

        await Promise.all(deletePromises);

        const getPromises = selectedUser.map((id: string) => {
          const url = `admin/realms/${orgId}/users/${id}/groups`;
          return ApiHttpClient.get(url);
        });

        await Promise.all(getPromises);
        success(`${selectedUser.length} ${selectedUser.length > 1 ? 'users' : 'user'}  left the group`);
        setPage(0);
        setFirstValue(0);
        setSelectedUser([]);
      } else {
        setPage(0);
        setFirstValue(0);
        const deleteUrl = `admin/realms/${orgId}/users/${query}/groups/${clientid}`;
        await ApiHttpClient.delete(deleteUrl, {});
        const url = `admin/realms/${orgId}/users/${query}/groups`;
        await ApiHttpClient.get(url);
        const number = selectedUser.length;
        success(`user left the group`);
        setSelectedUser([]);
      }
      setOpenConfirmation(false);
      fetchData();
    } catch (err) {
      console.error('error::-', err);
      error('Failed to leave the group');
    }
  };

  const handleClose = () => {
    setSelected([]);
    setSearchTerm('');
    setSearchAssignedTerm('');
    setOpenModal(false);
    setUsersRowsPerPage(10);
    setUsersPage(0);
    setUsersFirstValue(0);
  };

  const handleSubmit = async () => {
    try {
      const putPromises = selected.map((id: string) => {
        const url = `admin/realms/${orgId}/users/${id}/groups/${clientid}`;
        return ApiHttpClient.put(url, {});
      });

      await Promise.all(putPromises);

      const getPromises = selected.map((id: string) => {
        const url = `admin/realms/${orgId}/users/${id}/groups`;
        return ApiHttpClient.get(url);
      });
      await Promise.all(getPromises);
      selected.length > 0 &&
        success(`${selected.length} ${selected.length > 1 ? 'users' : 'user'}  added to the group`);
      setPage(0);
      setFirstValue(0);
      setSelected([]);
      fetchData();
      fetchUsersData();
      setSearchTerm('');
      setSearchAssignedTerm('');
      selected.length > 0 && handleClose();
    } catch (err) {
      console.error('error::-', err);
      error('Failed to add member to the group');
    }
  };

  useEffect(() => {
    const isAllSelected = users.every((user: any) => selected.includes(user.id));
    setUserChecked(isAllSelected);
  }, [selected, users]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelected = users.map((user: any) => user.id);
    if (event.target.checked) {
      setSelected((prev: any) => [...new Set([...prev, ...newSelected])]);
      setUserChecked(true);
    } else {
      setSelected((prev: any) => {
        return prev.filter((id: string) => !newSelected.includes(id));
      });
      setUserChecked(false);
    }
  };

  const handleSelectAllClickTable = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((user: any) => user.id);
      setSelectedUser(newSelected);
      return;
    }
    setSelectedUser([]);
  };

  const handleClick = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > -1) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleTableClick = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
    const selectedIndex = selectedUser.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedUser, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedUser.slice(1));
    } else if (selectedIndex === selectedUser.length - 1) {
      newSelected = newSelected.concat(selectedUser.slice(0, -1));
    } else if (selectedIndex > -1) {
      newSelected = newSelected.concat(selectedUser.slice(0, selectedIndex), selectedUser.slice(selectedIndex + 1));
    }
    setSelectedUser(newSelected);
  };

  const disableLeave = () => {
    return selectedUser.length === 0 || rows.length === 0;
  };
  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  const isUserSelected = (id: string) => selectedUser.indexOf(id) !== -1;

  const handleChangePage = (event: any, newPage: any) => {
    const firstValue = newPage * rowsPerPage;
    setFirstValue(firstValue);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setFirstValue(0);
  };

  const handleUserChangePage = (event: any, newPage: any) => {
    const userFirstValue = newPage * usersRowsPerPage;
    setUsersFirstValue(userFirstValue);
    setUsersPage(newPage);
    handleMemberUsers(searchTerm, newPage, usersRowsPerPage, userFirstValue);
  };

  const handleUserChangeRowsPerPage = (event: any) => {
    const value = parseInt(event.target.value, 10);
    setUsersRowsPerPage(value);
    setUsersPage(0);
    setUsersFirstValue(0);
    handleMemberUsers(searchTerm, 0, value, 0);
  };

  const handleMemberUsers = async (searchTermRole = '', page: any, usersRowsPerPage: any, usersFirstValue: any) => {
    const getUrl = searchTermRole
      ? `admin/realms/${orgId}/users?first=${usersFirstValue}&max=${
          usersRowsPerPage + presentData
        }&briefRepresentation=true&q=&search=${searchTermRole}`
      : `admin/realms/${orgId}/users?first=${usersFirstValue}&max=${
          usersRowsPerPage + presentData
        }&search=${searchTermRole}`;
    const membersUrl = `admin/realms/${orgId}/groups/${clientid}/members`;
    let memberResponse = await ApiHttpClient.get(membersUrl);
    const paginationUrl =
      searchTermRole == ''
        ? `admin/realms/${orgId}/users/count?search=${searchTermRole}`
        : `admin/realms/${orgId}/users/count?q=&search=${searchTermRole}`;

    const Usersresponse = await ApiHttpClient.get(getUrl);
    const paginationResponse = await ApiHttpClient.get(paginationUrl);
    // const paginatedFiltered = pagination(Usersresponse, page, usersRowsPerPage);
    const filteredUsers =
      Usersresponse.length > 0
        ? Usersresponse.filter((ele: any) => {
            return !memberResponse.some((item: any) => item.id === ele.id);
          })
        : [];
    // setSelected(memberResponse);
    SetUsersData(filteredUsers ? filteredUsers : []);
    setUsersCount(paginationResponse);
  };

  const handleOpenConfirmation = (userId: any) => {
    setSelectedUserId(userId);
    setOpenConfirmation(true);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      padding: '7px !important',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: '6px !important',
    },
  }));
  return (
    <>
      {loading ? (
        <SkeletonLoader rows={''} />
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              size="small"
              fullWidth
              type="search"
              id="outlined-basic"
              value={searchAssignedTerm}
              label="Search Assigned Members"
              variant="outlined"
              autoComplete="off"
              onChange={handleAssignedSearchInputChange}
            />
          </Grid>
          {accessArray.includes('manage-users') && (
            <Grid item xs={12} sm={6} textAlign="right">
              <Button
                size="medium"
                sx={{ marginRight: '20px', textAlign: 'center' }}
                variant="contained"
                disabled={disableLeave()}
                onClick={() => handleOpenConfirmation('')}
              >
                Leave
              </Button>

              <Button size="medium" variant="contained" onClick={() => handleClickOpen()}>
                Add Member
              </Button>
            </Grid>
          )}

          <Grid item xs={12}>
            {rows.length > 0 ? (
              <Grid>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }}>
                    <TableHead sx={{ position: 'sticky', top: 0, zIndex: 1 }}>
                      <TableRow>
                        <StyledTableCell>
                          <Checkbox
                            sx={{ color: 'white' }}
                            checked={rows.length > 0 && selectedUser.length === rows.length}
                            onChange={handleSelectAllClickTable}
                            inputProps={{ 'aria-label': 'select all users' }}
                          />
                        </StyledTableCell>
                        <StyledTableCell sx={{ cursor: 'pointer' }}>Groups </StyledTableCell>
                        <StyledTableCell>Action</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row: any, index) => {
                        const isItemSelected = isUserSelected(row.id);
                        return (
                          <StyledTableRow key={index}>
                            <StyledTableCell component="th" scope="row">
                              <Checkbox
                                checked={isItemSelected}
                                onChange={(event) => handleTableClick(event, row.id)}
                              />
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                              {row.username}
                            </StyledTableCell>

                            <StyledTableCell>
                              <Typography sx={{ cursor: 'pointer', marginRight: '10px' }} component="span">
                                <IconButton
                                  onClick={() => handleOpenConfirmation(row.id)}
                                  disabled={!accessArray.includes('manage-users')}
                                  color="error"
                                >
                                  {/* <DeleteIcon /> */}
                                  <Tooltip title="Leave" arrow placement="bottom">
                                    <ExitToAppIcon />
                                  </Tooltip>
                                </IconButton>
                              </Typography>
                              {/* <Tooltip title="Rename Group">
                                <Typography
                                  sx={{ cursor: 'pointer', marginRight: '10px' }}
                                  component="span"
                                  color={'primary'}
                                  // onClick={() => handleEditOpen(row.id, row.name, row)}
                                >
                                  <EditIcon />
                                </Typography>
                              </Tooltip> */}
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  component="div"
                  count={count}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={(event) => handleChangeRowsPerPage(event)}
                  rowsPerPageOptions={paginationRowsPerPage}
                  labelRowsPerPage={'Members per Page'}
                />
              </Grid>
            ) : (
              <Grid container spacing={3} justifyContent="center" sx={{ margin: 3 }}>
                <Grid item xs={12} sm={6} container direction="column" alignItems="center" justifyContent="center">
                  <Typography variant="body1" sx={{ textAlign: 'center', fontSize: '20px' }}>
                    No Users Found
                  </Typography>
                  <Typography variant="body2">Change your search criteria or add a user</Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}

      <ConfirmationDialog
        open={openConfirmation}
        onClose={() => setOpenConfirmation(false)}
        title="Confirm UnAssign"
        content={`Are you sure you want to unassign ${selectedUser.length > 1 ? 'users' : 'user'}?`}
        onConfirm={() => handleLeave(selectedUserId)}
      />

      <Model
        open={openModal}
        handeleConfirm={handleSubmit}
        title={'Add Member'}
        saveButtonName={`${
          selected.length > 0 ? `Add ${selected.length} ${selected.length > 1 ? 'users' : 'user'}` : `Add`
        }`}
        data-modal-backdrop="static"
        //  buttonState={isSubmit}
        height="auto"
        width={'800px'}
        cancelClick={() => handleClose()}
      >
        <Grid item xs={12}>
          <Grid item xs={12} sm={6}>
            <TextField
              size="small"
              fullWidth
              type="search"
              id="outlined-basic"
              value={searchTerm}
              label="Search Members"
              variant="outlined"
              autoComplete="off"
              onChange={handleSearchInputChange}
            />
          </Grid>
          {users && users.length > 0 ? (
            <Grid>
              <TableContainer component={Paper} sx={{ maxHeight: 450, overflowY: 'auto', marginTop: '15px' }}>
                <Table sx={{ minWidth: 700 }}>
                  <TableHead sx={{ position: 'sticky', top: 0, zIndex: 1 }}>
                    <TableRow>
                      <StyledTableCell>
                        <Checkbox
                          sx={{ color: 'white' }}
                          checked={userChecked}
                          onChange={handleSelectAllClick}
                          inputProps={{ 'aria-label': 'select all users' }}
                        />
                      </StyledTableCell>
                      <StyledTableCell>Username</StyledTableCell>
                      <StyledTableCell>Email</StyledTableCell>
                      <StyledTableCell>First Name</StyledTableCell>
                      <StyledTableCell>Last Name</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <TableRow>
                        <TableCell colSpan={5}>Loading...</TableCell>
                      </TableRow>
                    ) : (
                      users &&
                      users.map((user: any, index) => {
                        const isItemSelected = isSelected(user.id);
                        return (
                          <StyledTableRow key={user.id}>
                            <StyledTableCell component="th" scope="row">
                              <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, user.id)} />
                            </StyledTableCell>
                            <StyledTableCell>{user.username}</StyledTableCell>
                            <StyledTableCell>{user.email}</StyledTableCell>
                            <StyledTableCell>{user.firstName}</StyledTableCell>
                            <StyledTableCell>{user.lastName}</StyledTableCell>
                          </StyledTableRow>
                        );
                      })
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component="div"
                count={usersCount}
                page={usersPage}
                onPageChange={handleUserChangePage}
                rowsPerPage={usersRowsPerPage}
                onRowsPerPageChange={(event) => handleUserChangeRowsPerPage(event)}
                rowsPerPageOptions={paginationRowsPerPage}
                labelRowsPerPage={'Members per Page'}
              />
            </Grid>
          ) : (
            <Grid container spacing={3} justifyContent="center" sx={{ margin: 3 }}>
              <Grid item xs={12} sm={6} container direction="column" alignItems="center" justifyContent="center">
                <Typography variant="body1" sx={{ textAlign: 'center', fontSize: '20px' }}>
                  No Users Found
                </Typography>
                <Typography variant="body2">Change your search criteria or add a user</Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Model>
    </>
  );
}

export default GroupsMembers;
